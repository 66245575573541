import UIkit from 'uikit';
import Rails from '@rails/ujs';
import { trackEvent } from './utils/analytics';

window.addEventListener('DOMContentLoaded', () => {
  const reviewContainer = document.getElementById('review-container');
  const reviewForm = document.getElementById('review-form');
  const reviewFormSubmit = document.getElementById('review-form-submit');
  const reviewFormCommentContainer = document.getElementById('review-form-comment-container');
  const reviewFormComment = document.getElementById('review-form-comment');

  let rating = 0;
  function onStarClick(event) {
    reviewFormSubmit.hidden = false;
    reviewFormCommentContainer.hidden = false;
    rating = event.target.value;
  }

  function onSubmit(event) {
    event.preventDefault();
    reviewFormSubmit.disabled = true;

    trackEvent('review_submit_start');
    const data = new FormData();
    data.append('rating', rating);
    data.append('comment', reviewFormComment.value);

    Rails.ajax({
      url: '/reviews',
      type: 'POST',
      data,
      success() {
        trackEvent('review_submit_success');
        reviewContainer.remove();
        UIkit.notification('Review submitted, thank you!', {
          status: 'success',
          pos: 'bottom-center',
          timeout: 5000,
        });
      },
      error() {
        trackEvent('review_submit_error');
        reviewFormSubmit.disabled = false;
        UIkit.notification('Failed to submit your review, please try again later', {
          status: 'danger',
          pos: 'bottom-center',
          timeout: 5000,
        });
      },
    });
  }
  if (reviewForm) {
    reviewForm.onsubmit = onSubmit;
  }

  document.querySelectorAll("#review-form input[name='rating']").forEach((input) => {
    input.addEventListener('click', onStarClick);
  });
});

window.addEventListener('DOMContentLoaded', () => {
  const uploadAnother = document.getElementById('upload-another-link');
  const removeMetadata = document.getElementById('remove-metadata-link');
  const viewFullExif = document.getElementById('view-full-exif-link');

  if (uploadAnother) {
    uploadAnother.onclick = () => {
      trackEvent('upload_another_click');
    };
  }
  if (removeMetadata) {
    removeMetadata.onclick = () => {
      trackEvent('remove_metadata_click');
    };
  }
  if (viewFullExif) {
    viewFullExif.onclick = () => {
      trackEvent('view_full_exif_click');
    };
  }
});
