import UIkit from 'uikit';
import Rails from '@rails/ujs';
import Icons from 'uikit/dist/js/uikit-icons';
import { trackEvent } from './utils/analytics';

UIkit.use(Icons);

window.addEventListener('DOMContentLoaded', () => {
  const uploadContainer = document.getElementById('upload-container');
  if (!uploadContainer) {
    return;
  }

  const jsUpload = document.getElementById('js-upload');
  const removeExif = document.getElementById('remove-exif');
  const removeExifFilename = document.getElementById('remove-exif-filename');
  const removeExifDownloadUrl = document.getElementById('remove-exif-download-url');
  const removeExifViewMetadata = document.getElementById('remove-exif-view-metadata');
  const removeExifUploadAnother = document.getElementById('remove-exif-upload-another');
  const error = document.getElementById('js-upload-error');
  const overlay = document.getElementById('js-upload-progress-overlay');
  const input = document.getElementById('js-upload-input');
  const urlForm = document.getElementById('url-upload');
  const urlFormSubmit = document.getElementById('url-upload-submit');
  const urlFormInput = document.getElementById('url-upload-input');
  const target = uploadContainer.getAttribute('jimpl-target');
  const uploadUrl = `/uploads?target=${target}`;
  const targetIsRemoveExif = target === 'remove-exif';

  function onStart() {
    urlFormSubmit.disabled = true;
    input.disabled = true;
    overlay.hidden = false;
  }
  function onError() {
    urlFormSubmit.disabled = false;
    input.disabled = false;
    overlay.hidden = true;
    UIkit.modal(error).show();
  }
  function onSuccess(response) {
    if (targetIsRemoveExif) {
      const uploadData = typeof response === 'string' ? JSON.parse(response) : response;

      urlFormSubmit.disabled = true;
      urlForm.reset();
      urlForm.style.visibility = 'hidden';
      overlay.hidden = true;
      input.disabled = true;
      input.hidden = true;
      jsUpload.classList.add('js-upload-default-cursor');

      removeExifFilename.innerText = uploadData.name;
      removeExifDownloadUrl.href = `/results/${uploadData.id}/without-metadata`;
      removeExifViewMetadata.href = `/results/${uploadData.id}`;
      removeExif.hidden = false;
    } else {
      setTimeout(() => {
        urlFormSubmit.disabled = false;
        input.disabled = false;
        overlay.hidden = true;
      }, 5000);
      window.location.href = response;
    }
  }

  function uploadFromUrl() {
    const value = urlFormInput.value;
    if (value.trim()) {
      const data = new FormData();
      data.append('url', value);

      trackEvent('url_upload_start');
      onStart();
      Rails.ajax({
        url: uploadUrl,
        type: 'POST',
        data,
        success(data) {
          trackEvent('url_upload_success');
          onSuccess(data);
        },
        error() {
          trackEvent('url_upload_error');
          onError();
        },
      });
    }
  }

  if (urlForm) {
    urlForm.onsubmit = (event) => {
      event.preventDefault();
      uploadFromUrl();
    };

    const urlParams = new URLSearchParams(window.location.search);
    const url = urlParams.get('url');
    if (url && url.trim() && urlFormInput) {
      urlFormInput.value = url.trim();
      uploadFromUrl();
    }
  }

  if (removeExifUploadAnother) {
    removeExifUploadAnother.onclick = (event) => {
      event.preventDefault();

      urlFormSubmit.disabled = false;
      urlForm.style.visibility = 'unset';
      overlay.hidden = true;
      input.disabled = false;
      input.hidden = false;
      jsUpload.classList.remove('js-upload-default-cursor');

      removeExifFilename.innerText = '';
      removeExif.hidden = true;
    }
  }

  UIkit.upload('.js-upload', {
    url: uploadUrl,
    multiple: true,

    beforeSend(environment) {
      trackEvent('file_upload_start');
      const { headers } = environment;
      headers['X-CSRF-Token'] = document.querySelectorAll('meta[name="csrf-token"]')[0].content;
    },
    error() {
      trackEvent('file_upload_error');
      onError();
    },
    loadStart() {
      onStart();
    },
    completeAll() {
      trackEvent('file_upload_success');
      const [req] = arguments;
      onSuccess(req.response);
    },
  });
});

function stickyToTop() {
  var doc = document.documentElement;
  var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
  var stickyEl = document.getElementById('sticky-to-top');
  if (!stickyEl) {
    return;
  }

  stickyEl.hidden = top <= 300;
}

document.addEventListener('DOMContentLoaded', function () {
  stickyToTop();
});

window.addEventListener('scroll', function () {
  stickyToTop();
});

window.addEventListener('DOMContentLoaded', () => {
  const shareButtons = ['facebook', 'twitter', 'pinterest', 'linkedin'];
  shareButtons.forEach((network) => {
    const link = document.getElementById(`share-${network}-link`);
    if (link) {
      link.onclick = () => {
        trackEvent(`share_${network}_click`);
      };
    }
  });
});
